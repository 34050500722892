<template>
  <v-card>
    <v-card-title>
      Role Permissions
    </v-card-title>
    <v-card-actions>
      <v-text-field
        v-model="search"
        label="search"
      />
      <v-spacer/>
      <v-btn @click="createRole()">Add</v-btn>
      <v-btn @click="showEmpty=!showEmpty">Show Empty</v-btn>
    </v-card-actions>
    <v-card-text>
        <span
          class="caption"> Notes:
          <ul>
            <li>if no permissions are specified, the role will have read/write access on all pages.</li>
            <li>if a user has 'writePage' permissions, they can also read (you don't need to add the page twice)</li>
          </ul>
        </span>
      <v-data-table
        :items="filteredRoles"
        :headers="headers"
        disable-pagination
        hide-default-footer
        sort-by="name"
        :search="search"
      >
        <template v-slot:item.email="{item}">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template v-slot:item.readPages="{item}">
          <template>
            <v-select
              :disabled="!item.edit"
              class="ma-0 pa-0"
              solo flat
              v-model="item.readPages"
              :items="routes"
              item-text="path"
              item-value="name"
              @change="saveRole(item)"
              hide-details
              multiple
              small-chips
              label="no restrictions"
            />
          </template>
        </template>
        <template v-slot:item.writePages="{item}">
          <template>
            <v-select
              :disabled="!item.edit"
              class="ma-0 pa-0"
              solo flat
              v-model="item.writePages"
              :items="routes"
              item-text="path"
              item-value="name"
              @change="saveRole(item)"
              hide-details
              multiple
              small-chips
              label="no restrictions"
            />
          </template>
        </template>
        <template v-slot:item.actions="{item}">
          <v-btn icon @click="$set(item,'edit',true)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteRole(item)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/api';

export default {
  name: "Roles",
  data() {
    return {
      roles: [],
      diets: [],
      headers: 'id,name,readPages,writePages,api,users,actions'
        .split(',')
        .map(p => ({value: p, text: p})),
      showDeleted: false,
      routes: [],
      showEmpty: null,
      search: null
    }
  },
  mounted() {
    this.$router.options.routes.forEach(route => {
      if (route.meta && route.meta.requiresAuth) {
        this.routes.push({
          name: route.name
          , path: route.path
        });
      }
    });
    this.routes.sort((a, b) => a.path.localeCompare(b.path));
    this.fetchRoles();
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    filteredRoles() {
      if (this.showEmpty) {
        return this.roles;
      } else {
        return this.roles.filter(r => r.users.length > 0);
      }
    }
  },
  methods: {
    saveRole(role) {
      console.log('saving', JSON.stringify(role));
      api.put(`v2/role/${role.id}`, role)
        .finally(this.fetchRoles);
    },
    createRole() {
      console.log('creating role',);
      const name = prompt('enter name of role')
      api.post(`v2/role/`, {name})
        .finally(this.fetchRoles);
    },
    deleteRole(role) {
      return api
        .get('users/list')
        .then(response => {
          const users = response.data;
          return users;
        })
        .then(users => {
          const usersWithRole = users.filter(user => user.role === role.id)
          if (usersWithRole.length > 0) {
            alert(`you cannot delete this role as it is still being used by these users: ${usersWithRole.map(u => u.email).join(',')}`);
            throw new Error('role in use');
          }
        })
        .then(() => {
          api.delete(`v2/role/${role.id}`)
            .then(this.fetchRoles);
        })
        .catch(e => {
          console.warn('delete role failed', e);
        });
    },
    fetchRoles() {
      return api.get('v2/role')
        .then(response => {
          this.roles = response.data
            .map(r => ({...r, users: r.users.map(u => u.email).join(', ')}))
            .sort((a, b) => a.name.localeCompare(b.name));
        })
    }
  }
}
</script>

<style scoped>

</style>
