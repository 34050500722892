import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Role Permissions ")]),_c(VCardActions,[_c(VTextField,{attrs:{"label":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.createRole()}}},[_vm._v("Add")]),_c(VBtn,{on:{"click":function($event){_vm.showEmpty=!_vm.showEmpty}}},[_vm._v("Show Empty")])],1),_c(VCardText,[_c('span',{staticClass:"caption"},[_vm._v(" Notes: "),_c('ul',[_c('li',[_vm._v("if no permissions are specified, the role will have read/write access on all pages.")]),_c('li',[_vm._v("if a user has 'writePage' permissions, they can also read (you don't need to add the page twice)")])])]),_c(VDataTable,{attrs:{"items":_vm.filteredRoles,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","sort-by":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.readPages",fn:function(ref){
var item = ref.item;
return [[_c(VSelect,{staticClass:"ma-0 pa-0",attrs:{"disabled":!item.edit,"solo":"","flat":"","items":_vm.routes,"item-text":"path","item-value":"name","hide-details":"","multiple":"","small-chips":"","label":"no restrictions"},on:{"change":function($event){return _vm.saveRole(item)}},model:{value:(item.readPages),callback:function ($$v) {_vm.$set(item, "readPages", $$v)},expression:"item.readPages"}})]]}},{key:"item.writePages",fn:function(ref){
var item = ref.item;
return [[_c(VSelect,{staticClass:"ma-0 pa-0",attrs:{"disabled":!item.edit,"solo":"","flat":"","items":_vm.routes,"item-text":"path","item-value":"name","hide-details":"","multiple":"","small-chips":"","label":"no restrictions"},on:{"change":function($event){return _vm.saveRole(item)}},model:{value:(item.writePages),callback:function ($$v) {_vm.$set(item, "writePages", $$v)},expression:"item.writePages"}})]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$set(item,'edit',true)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteRole(item)}}},[_c(VIcon,[_vm._v("mdi-trash-can")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }